import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import FormField from "../../../../components/FormField/FormField";
import { Formik, Form } from "formik";
import { useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router-dom";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const professionOptions = [
  { value: "Doctor", label: "Doctor" },
  { value: "Student", label: "Student" },
  { value: "Nurse", label: "Nurse" },
  { value: "Dentist", label: "Dentist" },
  { value: "Physician", label: "Physician" },
  { value: "Physical Therapy", label: "Physical Therapy" },
];
const ProfileDetails = ({}) => {
  const jsonString = localStorage.getItem("currentUser");
  const [user, setCurrentUser] = useState(jsonString && JSON.parse(jsonString));
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");
  const navigate = useNavigate();

  const initialValues = useMemo(
    () => ({
      first_name: user?.first_name,
      last_name: user?.last_name,
      profession: user?.profession,
    }),
    []
  );
  const [resetPassword, setResetPassword] = useState(false);
  const [isEdit, setEdit] = useState(false);

  return (
    <Box sx={{ ml: { md: 20 }, mr: { md: 20 } }}>
      <Box style={{ display: "flex", flexDirection: "row", columnGap: 20 }}>
        <Avatar>
          {user?.first_name?.charAt(0).toUpperCase() +
            user?.last_name?.charAt(0).toUpperCase()}
        </Avatar>
        <Typography
          sx={{ fontSize: { xs: 23, md: 25 } }}
          style={{ fontFamily: "Montserrat", fontWeight: 600 }}
        >
          Hello, {user?.first_name + " " + user?.last_name}
        </Typography>
        <EditIcon onClick={() => setEdit(true)} />

        <Button
          sx={{ display: { xs: "none", md: "flex" } }}
          type="submit"
          variant="outlined"
          style={{
            color: "black",
            fontFamily: "Montserrat",
            borderColor: "black",
            marginLeft: 10,
          }}
          onClick={() => {
            localStorage.setItem("currentUser", null);
            navigate("/");
          }}
        >
          Logout
        </Button>
      </Box>

      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          axios
            .patch(`${API_URL}/users/${user?.id}`, values)
            .then((response) => {
              setLoading(false);
              const jsonString = JSON.stringify(response.data);
              localStorage.setItem("currentUser", jsonString);
              setCurrentUser(jsonString);
              setEdit(false);
              setResetPassword(false);
            })
            .catch((e) => {
              setLoading(false);
              setError("Invalid email or password");
            });
        }}
      >
        {({ values, errors, dirty }) => (
          <Form>
            {" "}
            <Grid container style={{ marginTop: 30 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 17 }}>
                  <FormField
                    type="input"
                    name={`first_name`}
                    label="First name"
                    variant="outlined"
                    fullwidth
                    disabled={!isEdit}
                    customizedStyling={customTextFieldStyling}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 17 }}>
                  <FormField
                    type="input"
                    name={`last_name`}
                    label="Last name"
                    variant="outlined"
                    fullwidth
                    disabled={!isEdit}
                    customizedStyling={customTextFieldStyling}
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ marginBottom: 17 }}>
                  <FormField
                    type="select"
                    name={`profession`}
                    label="Profession"
                    variant="outlined"
                    fullwidth
                    options={professionOptions}
                    disabled={!isEdit}
                    customizedStyling={customTextFieldStyling}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              onClick={() => setResetPassword(true)}
              variant="outlined"
              style={{
                color: "black",
                fontFamily: "Montserrat",
                borderColor: "black",
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              Reset password
            </Button>
            {resetPassword && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="input"
                    name={`password`}
                    label="Password"
                    variant="outlined"
                    fullwidth
                    customizedStyling={customTextFieldStyling}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="input"
                    name={`confirm_password`}
                    label="Confirm password"
                    variant="outlined"
                    fullwidth
                    customizedStyling={customTextFieldStyling}
                    required
                  />
                </Grid>
              </Grid>
            )}
            {(isEdit || resetPassword) && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "black",
                  fontFamily: "Montserrat",
                  borderColor: "black",
                  marginTop: 50,
                  marginBottom: 50,
                  marginLeft: 10,
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Save"
                )}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default ProfileDetails;
